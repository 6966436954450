import { PageProps } from 'gatsby';
import { SchemaData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '../../../components/View';
import Form from '../../../containers/Admin/Form';
import params from '../../../params/schema.json';
import TemplateAdmin from '../../../templates/admin';
import requireUser, { UserProps } from '../../../utils/requireUser';

const PageSchemasCreate: FC<PageProps & UserProps> = () => {
  const model = new SchemaData({ params });

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          itemPathnamePrefix="/admin/schemas/"
          model={model}
          name="schema"
          type="create"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageSchemasCreate, 'admin');
